import { Component, input, OnInit } from "@angular/core";
import { DynamicService } from "src/app/shared/services/dynamic.service";


@Component({
    selector: "app-banner-grid-two-column",
    templateUrl: "./banner-grid-two-column.component.html",
    styleUrl: "./banner-grid-two-column.component.scss",
  })
  export class BannerGridTwoColumnGadgetComponent implements OnInit {
    colData = input<any[]>([], { alias: "data" });
    rowClass = input<any[]>([], { alias: "rowClass" });
    collection=input<any>(null,{ alias:"collection"});
    row=input<any>(null,{ alias:"row"});
  
    constructor(private dynamicService: DynamicService) {}
  
  
    ngOnInit(): void {
        console.log(this.colData(),this.row());
    }

}