<div class="container-fluid-lg">
    <div class="product-wrapper no-arrow" [class]="'category-slider'">
      <owl-carousel-o [options]="colData()[0]?.content?.sliderOption">
        <ng-template carouselSlide *ngFor="let category of colData()[0]?.content?.categories">
          <div>
            <div class="shop-category-box border-0">
              <a href="javascript:void(0)"  class="circle-1">
                <img [src]="category?.image"
                [alt]="category?.name">
              </a>
              <div class="category-name">
                <h6>{{ category.name }}</h6>
              </div>
          </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
    </div>