import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ThemeOption } from '../interface/theme-option.interface';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeOptionService {

  public preloader: boolean = true;
  public theme_color: string;

  constructor(private http: HttpClient,private httpService:HttpService) { }

  getThemeOption(): Observable<ThemeOption> {
    return this.http.get<ThemeOption>(`${environment.URL}/theme-option.json`);
  }

  getHomeDynamic(): Observable<any> {
    let url='api/admin/website?action=getWebSite'
    return this.httpService.get<any>(`${environment.apiServer}`+url);
  }

  getCollection():Observable<any>{
    let url='api/admin/collection?action=getCollectionByIdAndProducts&collectionId=a08182&pageNumber=1&pageSize=10'
    return this.httpService.get<any>(`${environment.apiServer}`+url)
  }

}
