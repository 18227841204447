import { Component, Inject, PLATFORM_ID,OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Store, Select } from '@ngxs/store';
import { Observable, forkJoin, map } from 'rxjs';
import { ThemeOptionState } from '../shared/state/theme-option.state';
import { Option, ThemeOption } from '../shared/interface/theme-option.interface';
import { GetCategories } from '../shared/action/category.action';
import { ThemeOptionService } from '../shared/services/theme-option.service';
import { GetDealProducts } from '../shared/action/product.action';
import { GetUserDetails } from '../shared/action/account.action';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  // @Select(ThemeOptionState.themeOptions) themeOption$: Observable<Option>;
  @Select(ThemeOptionState.cookies) cookies$: Observable<boolean>;
  @Select(ThemeOptionState.exit) exit$: Observable<boolean>;

  public cookies: boolean;
  public exit: boolean;
  themeOption:Option

  constructor(
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: Object,
    public themeOptionService: ThemeOptionService
  ) {
    this.cookies$.subscribe((res) => (this.cookies = res));
    this.exit$.subscribe((res) => (this.exit = res));

    this.themeOptionService.preloader = true;

    this.store.dispatch(new GetUserDetails());

    const getCategories$ = this.store.dispatch(
      new GetCategories({ status: 1 })
    );

    const getProduct$ = this.store.dispatch(
      new GetDealProducts({ status: 1, paginate: 2 })
    );

    forkJoin([getCategories$, getProduct$]).subscribe({
      complete: () => {
        this.themeOptionService.preloader = false;
      },
    });
  }

  async ngOnInit(){
    console.log("OnInit");
    let data$ = await this.themeOptionService.getThemeOption().pipe(
      map((themeOption: ThemeOption) => themeOption.options)
    );
    data$.subscribe(themeOption => {
      this.themeOption=themeOption});
  }

  setLogo() {
    var headerLogo;
    var footerLogo;
    var footerClass;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      if (
        window.location.pathname == '/theme/berlin' ||
        window.location.pathname == '/theme/luxe'
      ) {
        headerLogo = 'assets/images/logo/6.png';
        footerLogo = 'assets/images/logo/4.png';
        footerClass = 'footer-section-2 footer-color-3';
      } else {
        headerLogo = this.themeOption?.logo?.header_logo?.original_url;
          footerLogo = this.themeOption?.logo?.footer_logo?.original_url;
          footerClass =
          this.themeOption?.footer.footer_style === 'dark_mode'
              ? 'footer-section-2 footer-color-3'
              : '';
        
        // this.themeOption$.subscribe((theme) => {
        //   console.log(theme);
        //   headerLogo = theme?.logo?.header_logo?.original_url;
        //   footerLogo = theme?.logo?.footer_logo?.original_url;
        //   footerClass =
        //     theme?.footer.footer_style === 'dark_mode'
        //       ? 'footer-section-2 footer-color-3'
        //       : '';
        // });
      }
    }
    return {
      header_logo: headerLogo,
      footer: { footer_logo: footerLogo, footer_class: footerClass },
    };
  }
}
