<div class="footer-contact">
    <ul>
        <li *ngIf="data?.footer?.support_number">
            <div class="footer-number">
                <i class="ri-phone-line"></i>
                <div class="contact-number">
                    <h6 class="text-content">{{ 'customer support_24_7' | translate }} :</h6>
                    <h5>{{ data?.footer?.support_number }}</h5>
                </div>
            </div>
        </li>
        <li *ngIf="data?.footer?.support_email">
            <div class="footer-number">
                <i class="ri-mail-line"></i>
                <div class="contact-number">
                    <h6 class="text-content">{{ 'email address' | translate }} :</h6>
                    <h5>{{ data?.footer?.support_email }}</h5>
                </div>
            </div>
        </li>
        <!-- <li class="social-app mb-0" *ngIf="data?.footer?.play_store_url || data?.footer?.app_store_url">
            <h5 class="mb-2 text-content">{{ 'download app' | translate }} :</h5>
            <ul>
                <li class="mb-0" *ngIf="data?.footer?.play_store_url">
                    <a href="{{ data?.footer?.play_store_url }}" target="_blank">
                        <img src="assets/images/playstore.svg" alt="playstore">
                    </a>
                </li>
                <li class="mb-0" *ngIf="data?.footer?.app_store_url">
                    <a href="{{ data?.footer?.app_store_url }}" target="_blank">
                        <img src="assets/images/appstore.svg" alt="appstore">
                    </a>
                </li>
            </ul>
        </li> -->
    </ul>
</div>
