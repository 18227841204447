<!-- Cart Style Basic -->
<app-button
  [type]="'button'"
  [class]="'btn p-0 position-relative header-wishlist'"
  [spinner]="false"
  [id]="'cart_sidebar_btn'"
  (click)="cartStyle == 'cart_sidebar' && cartToggle(true)" *ngIf="style == 'basic'">
    <i class="ri-shopping-cart-line"></i>
    <span class="position-absolute top-0 start-100 translate-middle badge" *ngIf="cartService?.cartQuantity">
        {{ cartService?.cartQuantity }} <span class="visually-hidden">{{ 'unread_messages' | translate }}</span>
    </span>
</app-button>

<!-- Cart Style Classic -->
<a href="javascript:void(0)" class="header-icon swap-icon"
    (click)="cartStyle == 'cart_sidebar' && cartToggle(true)"
    *ngIf="style == 'classic'">
    <small class="badge-number badge-light" *ngIf="cartService?.cartQuantity">
        {{ cartService?.cartQuantity}}
    </small>
    <i class="ri-shopping-cart-line"></i>
</a>

<!-- Cart On Hover -->
<div class="onhover-div"
    [ngClass]="{ 'fixed-cart': cartStyle == 'cart_sidebar' }"
    [class.show]="(sidebarCartOpen$ | async)">
    <div class="cart-title">
        <h4>{{ 'shopping_cart' | translate }}</h4>
        <a href="javascript:void(0)" (click)="cartToggle(false)">
            <i class="ri-close-line"></i>
        </a>
    </div>
    <!-- <div class="pere-text-box success-box" *ngIf="(cartItem$ | async)?.length">
        <p *ngIf="shippingFreeAmt > cartTotal else content">
            {{ 'spend' | translate }} <span class="shipping">{{ (shippingFreeAmt - cartTotal) | currencySymbol }}</span> {{ 'more_and_enjoy' | translate }} <span class="shipping">{{ 'free_shipping' | translate }}!</span>
        </p>
        <ng-template #content>
            <p><span class="shipping">{{ 'congratulations' | translate }}!</span> {{ 'enjoy_free_shipping_on_us' | translate }}!</p>
        </ng-template>
      <div class="progress"
        [ngClass]="{ 'danger-progress': shippingCal <= 30, 'warning-progress': shippingCal >= 31 && shippingCal <= 80 }">
        <div class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
            [ngStyle]="{ width: shippingCal+'%' }">
          <i class="ri-truck-line"></i>
        </div>
      </div> 
    </div> -->
    <ul class="cart-list" *ngIf="cartService?.cartItem && cartService?.cartItem?.products && cartService?.cartItem?.products?.length">
        <li class="product-box-contain" *ngFor="let item of cartService.cartItem.products">
            <div class="drop-cart">
                <a href="javascript:void(0)" class="drop-image">
                    <img [src]="item?.media && item?.media?.thumbnail
                            ? item?.media?.thumbnail: 'assets/images/product.png'" class="img-fluid" [alt]="item?.product?.productTitle">
                </a>
                <div class="drop-contain">
                    <a href="javascript:void(0)">
                        <h5>{{item?.product?.productTitle }}</h5>
                    </a>
                    <h6>{{ item?.price}} ₹</h6>
                    <div class="cart_qty qty-box product-qty">
                      <div class="input-group h-100">
                        <app-button
                            [class]="'btn qty-left-minus'"
                            [spinner]="false"
                            (click)="updateQuantity(item, -1)">
                            <i class="ri-subtract-line" *ngIf="item.qty> 1"></i>
                            <i class="ri-delete-bin-line" *ngIf="item.qty<= 1"></i>
                        </app-button>
                        <input class="form-control input-number qty-input" type="number" name="quantity" [value]="item?.qty" readonly>
                        <app-button
                            [class]="'btn qty-left-minus'"
                            [spinner]="false"
                            (click)="updateQuantity(item, 1)">
                            <i class="ri-add-line"></i>
                        </app-button>
                      </div>
                    </div>
                    <div>
                        <div class="header-button-group">
                            <app-button [class]="'edit-button close_button'" [spinner]="false" (click)="VariationModal.openModal(item)">
                                <i class="ri-pencil-line"></i>
                            </app-button>
                            <app-button [class]="'delete-button close_button'"  [spinner]="false" >
                                <i class="ri-delete-bin-line"></i>
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <div class="empty-cart-box" *ngIf="!(cartService?.cartItem && cartService?.cartItem?.products && cartService?.cartItem?.products?.length)">
        <i class="ri-shopping-cart-line"></i>
        <h5>{{ 'your cart is currently empty' | translate }}</h5>
    </div>
    <div class="bottom-box" *ngIf="cartService?.cartItem && cartService?.cartItem?.products && cartService?.cartItem?.products?.length">
        <p class="free">{{ 'shipping_and_taxes_are_calculated_at_checkout' | translate }}</p>
        <div class="price-box">
            <h5>{{ 'Total' | translate }}:</h5>
            <h4 class="fw-bold">{{cartService.cartItem.cartTotal}} ₹</h4>
        </div>
        <div class="button-group">
            <a [routerLink]="['/cart']" class="btn btn-sm cart-button" (click)="cartToggle(false)">
                {{ 'View Cart' | translate }}
            </a>
            <a (click)="goToCheckOut()" class="btn btn-sm cart-button theme-bg-color text-white" (click)="cartToggle(false)">
                {{ 'CHECKOUT' | translate }}
            </a>
        </div>
    </div>
</div>
<!-- <div class="bg-overlay" [class.show]="cartStyle == 'cart_sidebar' && (sidebarCartOpen$ | async)" (click)="cartStyle == 'cart_sidebar' && cartToggle(false)"></div> -->
<!-- Free Shipping Celebration -->
<!-- <div class="confetti-wrapper" [class.show]="confetti == 1 && (sidebarCartOpen$ | async)">
    <ng-container *ngFor="let item of confettiItems">
        <div class="confetti-{{item}}"></div>
    </ng-container>
</div> -->
<!-- Variation Customize -->
<app-variation-modal #variationModal></app-variation-modal>
