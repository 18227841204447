<ng-template #addressModal let-modal>
  <div class="modal-header">
    <h3 class="fw-semibold"> {{ (!isEdit ? 'Add Address' : 'Edit Address') | translate  }}</h3>
    <app-button [class]="'btn btn-close'" [spinner]="false" [id]="'address_modal_close_btn'"
      (click)="modal.dismiss('Cross click')">
      <i class="ri-close-line"></i>
    </app-button>
  </div>
  <form [formGroup]="form" class="" >
    <div class="modal-body address-form">
      <div class="row g-4">
        <div class="col-6">
          <div class="">
            <label class="form-label" for="firstName">First Name</label>
            <input class="form-control" id="firstName" type="text" placeholder="First Name"
              formControlName="firstName" />
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="lastName">Last Name</label>
            <input class="form-control" id="lastName" type="text" placeholder="Last Name"
              formControlName="lastName" />
          </div>
        </div>
        <div class="col-12">
          <div class="">
            <label class="form-label" for="address1">{{ 'address' | translate }}</label>
            <textarea class="form-control" id="address" rows="4" type="text" placeholder="{{ 'Enter address' | translate }}"
              formControlName="street" ></textarea>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">{{ 'Pincode' | translate }}</label>
            <input class="form-control" id="pincode" type="text" placeholder="{{ 'Enter pincode' | translate }}"
              formControlName="pincode" numbersOnly (change)="getPincodeData($event.target.value)"/>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">{{ 'Post' | translate }}</label>
            <ng-select [items]="postOfficeList" bindLabel="Name" bindValue="Name" formControlName="post"  (change)="onPincodeChange($event)"></ng-select>
          </div>
        </div>
        <!-- <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">{{ 'country' | translate }}</label>
            <ng-container>
              <select class="size form-select" formControlName="country_id">
                  <ng-container *ngFor="let value of countryList; let indexx = index;">
                      <option  [value]="value">
                          {{ value }}
                      </option>
                  </ng-container>
              </select>
            </ng-container>
            
          </div>
        </div> -->
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">{{ 'State' | translate }}</label>
            <ng-container>
              <ng-select [items]="state" formControlName="state_id" bindLabel="itemName" bindValue="itemName"></ng-select>
              <!-- <select class="size form-select" #selectedStateValue formControlName="state_id">
                  <ng-container *ngFor="let value of state; let indexx = index;">
                      <option   [value]="value.itemName">
                          {{ value.itemName }}
                      </option>
                  </ng-container>
              </select> -->
            </ng-container>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">{{ 'City' | translate }}</label>
            <ng-container>
              <input class="form-control" id="city" type="text" placeholder="city"
              formControlName="city" />
              <!-- <ng-select [items]="city" formControlName="city" bindLabel="city" bindValue="city"></ng-select> -->
              <!-- <select class="size form-select" #selectedCityValue formControlName="city">
                  <ng-container *ngFor="let value of city; let indexx = index;">
                      <option   [value]="value.city">
                          {{ value.city }}
                      </option>
                  </ng-container>
              </select> -->
            </ng-container>
            
            <!-- <input class="form-control" id="city" type="text" placeholder="{{ 'enter_city' | translate }}" formControlName="city" /> -->
          </div>
        </div>
        <div class="col-8 ">
          <div class="">
            <label class="form-label" for="address1">{{ 'Phone Number' | translate }}</label>
            
            <input type="number" class="form-control " formControlName="phone"
              placeholder="{{ 'Enter phone' | translate }}" name="phone">
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <app-button [class]="'btn btn-theme-outline btn-md fw-bold'" [type]="'button'" [spinner]="false"
          [id]="'cancel_addres_btn'" (click)="modal.dismiss('Cancel')">
          {{ 'cancel' | translate }}
        </app-button>
        @if(isEdit==true){
          <app-button (click)="update()" [class]="'btn theme-bg-color btn-md fw-bold text-light'" [id]="'submit_address_btn'">
            {{ 'update' | translate }}
          </app-button>

        }@else{
          <app-button (click)="submit()" [class]="'btn theme-bg-color btn-md fw-bold text-light'" [id]="'submit_address_btn'">
            {{ 'submit' | translate }}
          </app-button>
        }
        
      </div>
    </div>
  </form>
</ng-template>
