import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsGadgetComponent } from './news-gadget/news-gadget.component';
import { ClockGadgetComponent } from './clock-gadget/clock-gadget.component';
import { CollectionGadgetComponent } from './collection-gadget/collection-gadget.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { HeroGadgetComponent } from './hero-gadget/hero-gadget.component';
import { HeroSlideGadgetComponent } from './hero-slide-gadget/hero-slide-gadget.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BannerGridTwoColumnGadgetComponent } from './banner-grid-two-column/banner-grid-two-column.component';
import { BannerGridThreeColumnGadgetComponent } from './banner-grid-three-column/banner-grid-three-column.component';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { IconNameGadgetComponent } from './icon-name-gadget/icon-name-gadget.component';


@NgModule({
  declarations: [
    NewsGadgetComponent,
    ClockGadgetComponent,
    CollectionGadgetComponent,
    HeroGadgetComponent,
    HeroSlideGadgetComponent,
    BannerGridTwoColumnGadgetComponent,
    BannerGridThreeColumnGadgetComponent,
    IconNameGadgetComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SlickCarouselModule,
    CarouselModule,
    RouterModule
  ],
  exports:[
    NewsGadgetComponent,
    ClockGadgetComponent,
    CollectionGadgetComponent,
    HeroGadgetComponent,
    HeroSlideGadgetComponent,
    BannerGridTwoColumnGadgetComponent,
    BannerGridThreeColumnGadgetComponent,
    IconNameGadgetComponent
  ]
})
export class GadgetsModule { }
