import { Component, input, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { DynamicService } from "src/app/shared/services/dynamic.service";

@Component({
    selector: "app-icon-name-gadget",
    templateUrl: "./icon-name-gadget.component.html",
    styleUrl: "./icon-name-gadget.component.scss",
  })
  export class IconNameGadgetComponent implements OnInit {
    colData = input<any[]>([], { alias: "data" });
    rowClass = input<any[]>([], { alias: "rowClass" });
    collection=input<any>(null,{ alias:"collection"});
    row=input<any>(null,{ alias:"row"});

    constructor(private dynamicService: DynamicService) {}
  
  
    ngOnInit(): void {
        console.log(this.colData()[0].content.slideConfig);
    }

}