import { Component, input, OnInit } from "@angular/core";
import { DynamicService } from "src/app/shared/services/dynamic.service";


@Component({
    selector: "app-hero-gadget",
    templateUrl: "./hero-gadget.component.html",
    styleUrl: "./hero-gadget.component.scss",
  })
  export class HeroGadgetComponent implements OnInit {
    colData = input<any[]>([], { alias: "data" });
    rowClass = input<any[]>([], { alias: "rowClass" });
    collection=input<any>(null,{ alias:"collection"});
    row=input<any>(null,{ alias:"row"});
  
    constructor(private dynamicService: DynamicService) {}
  
  
    ngOnInit(): void {
        console.log(this.colData(),this.row());
    }

}