<!-- <div [ngClass]="rowClass" class="row-class">
  @for (product of data.cols; track $index) {
  <div [id]="product?.id"  class="column-class col-md-3 col-sm-12">
    <br>
    <app-product-box [productGadget]="product" [class]="'product-box-3'" [style]="'horizontal'"></app-product-box>    
  </div>
  }
</div> -->

<div [ngClass]="rowClass()" class="row-class">
  @for (item of data.cols; track $index) {
  <div [id]="item.id" [ngClass]="item.colClass" class="column-class">
    <div class="card">
      @if(item?.content && item?.content?.image){
        <img [src]="item?.content?.image" alt="Card image cap" class="card-img-top img-fluid w-50 mx-auto my-0" />
      }@else if(item?.content && item?.content?.media[0]?.thumbnail!=''){
        <img [src]="item?.content?.media[0]?.thumbnail" alt="Card image cap" class="card-img-top img-fluid w-50 mx-auto my-0" />
      }@else{
        <img src="assets/images/product/product.png" alt="Card image cap" class="card-img-top img-fluid w-50 mx-auto my-0" />
      }
      <div class="card-body">
        @if(item?.content?.title){
          <h4 class="card-title mt-0">{{ item?.content?.title }}</h4>
        }@else if(item?.content?.productTitle){
          <h4 class="card-title mt-0">{{ item?.content?.productTitle }}</h4>
        }
        @if(item?.content?.desc){
          <p class="card-text">
            {{ item?.content?.desc }}
          </p>
        }@else if(item?.content?.productDescription){
          <p class="card-text">
            {{ item?.content?.productDescription }}
          </p>
        }
        <a href="javascript: void(0);" class="btn btn-primary">Button</a>
      </div>
    </div>
  </div>
  }
</div>