<div [ngClass]="rowClass()" class="row-class">
    @for (item of product.slice(1,3); track $index) {
    <div   class="column-class col-md-4 col-sm-12" (click)="goToCollection()">
        <div class="card">
            <img [src]="item.media[0].thumbnail"  alt="Card image cap" class="card-img-top img-fluid w-50 mx-auto my-0" />
            <div class="card-body product-detail">
                <h4 class="card-title mt-0">{{ item?.productTitle }}</h4>
                
                <h5 class="sold text-content">
                    <span class="theme-color price">{{item?.price}}₹</span>
                    <del *ngIf="item?.compareAtPrice">{{item?.compareAtPrice}}₹</del>
                </h5>
            </div>
        </div>
    </div>
    }
</div>