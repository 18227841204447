<!-- Topbar -->
<div class="header-top" [class.bg-dark]="data?.header?.page_top_bar_dark">
    <div class="container-fluid-lg">
        <div class="row justify-content-center">

            <div class="col-xxl-6 col-lg-9 d-lg-block d-none pt-1">
                <div class="header-offer">
                    <app-notice [content]="data?.header?.top_bar_content"></app-notice>
                </div>
            </div>
        </div>
    </div>
</div>


