import { Component, TemplateRef, ViewChild, PLATFORM_ID, Inject, Input, EventEmitter, Output } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';
import { CreateAddress, UpdateAddress } from '../../../../action/account.action';
import { CountryState } from '../../../../state/country.state';
import { StateState } from '../../../../state/state.state';
import { UserAddress } from '../../../../interface/user.interface';
import * as data from '../../../../data/country-code';
import { CartService } from 'src/app/shared/services/cart.service';

@Component({
  selector: 'address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent {
  @Output() addAddress: EventEmitter<any> = new EventEmitter();

  @Input() isEdit:boolean=false;

  @Input() selectedAddress:any;

  public form: FormGroup;
  public closeResult: string;
  public modalOpen: boolean = false;

  public states$: Observable<Select2Data>;
  public address: UserAddress | null;
  public codes = data.countryCodes;

  state:any=[];
  city:any=[];
  countryList = ['India','Canada','US','Sri Lanka','Nepal','UAE','Bangladesh'];
  postOfficeList:any=[];
  selectedPost:any;
  

  @ViewChild("addressModal", { static: false }) AddressModal: TemplateRef<string>;
  @Select(CountryState.countries) countries$: Observable<Select2Data>;

  constructor(private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private formBuilder: FormBuilder,private cartService:CartService) {
    this.form = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName:new FormControl('',[Validators.required]),
      street: new FormControl('', [Validators.required]),
      state_id: new FormControl('', [Validators.required]),
      country_id: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      country_code: new FormControl('91', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      post:new FormControl(null)
    })

    this.getAllCity();
    this.getAllState();
    console.log(this.isEdit);
  }

  ngOnInit() {
    console.log(this.isEdit,this.selectedAddress);

    if(this.isEdit==true){
      console.log(this.selectedAddress);
      this.initializeForm(this.selectedAddress);
    }
  }

  initializeForm(address){
    if(address.post!=null || address.post!=undefined || address.post!=''){
      this.getEditPincodeData(address.pinCode);
    }
    let firstName = address.firstName;
    let lastName=address.lastName;
    let street=address.address;
    let city=address.city;
    let country_id=address.country;
    let phone=address.phone;
    let pincode=address.pinCode;
    let state=address.state;
    let post=address.post;
    this.form = this.formBuilder.group({
      firstName: new FormControl(firstName, [Validators.required]),
      lastName:new FormControl(lastName,[Validators.required]),
      street: new FormControl(street, [Validators.required]),
      state_id: new FormControl(state, [Validators.required]),
      country_id: new FormControl(country_id, [Validators.required]),
      city: new FormControl(city, [Validators.required]),
      pincode: new FormControl(pincode, [Validators.required]),
      country_code: new FormControl('91', [Validators.required]),
      phone: new FormControl(phone, [Validators.required, Validators.pattern(/^[0-9]*$/)]),
      post:new FormControl(post)
    })
  }


  countryChange(data: Select2UpdateEvent) {
    if(data && data?.value) {
      this.states$ = this.store
          .select(StateState.states)
          .pipe(map(filterFn => filterFn(+data?.value)));
      if(!this.address)
        this.form.controls['state_id'].setValue('');
    } else {
      this.form.controls['state_id'].setValue('');
    }
  }

  async getAllCity() {
    this.cartService.getCityList().subscribe({
      next: (res) => {
        if (res) {
          this.city = Object.keys(res.body).map((key) => {
            return res.body[key];
          });
        }
      }
    })
  }
  async getAllState() {
    this.cartService.getStateList().subscribe({
      next: (res) => {
        if (res) {
          this.state = Object.keys(res.body).map((key) => {
            return res.body[key];
          });
        }
      }
    })
  }

  async openModal(value?: UserAddress) {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalOpen = true;
      this.patchForm(value);
      this.modalService.open(this.AddressModal, {
        ariaLabelledBy: 'address-add-Modal',
        centered: true,
        windowClass: 'theme-modal modal-lg'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }


  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  patchForm(value?: UserAddress) {
    if(value) {
      this.address = value;
      this.form.patchValue({
        user_id: value?.user_id,
        title: value?.title,
        street: value?.street,
        country_id: value?.country_id,
        state_id: value?.state_id,
        city: value?.city,
        pincode: value?.pincode,
        country_code: value?.country_code,
        phone: value?.phone
      });
    } else {
      this.address = null;
      this.form.reset();
      this.form?.controls?.['country_code'].setValue('91');
    }
  }

  getPincodeData(value){
    console.log(value);
    if(value.length==6){
      this.cartService.getPinCodeData(value).subscribe((res:any)=>{
        console.log(res);
        this.postOfficeList=res[0].PostOffice;
        console.log(this.postOfficeList);
        this.selectedPost=this.postOfficeList[0];
        console.log(this.selectedPost);
        this.form.patchValue({ post: this.selectedPost?.Name });
        this.form.patchValue({ city: this.selectedPost.District });
        this.form.patchValue({ state_id: this.selectedPost.State });

      })
    }

  }

  getEditPincodeData(value){
    console.log(value);
    if(value.length==6){
      this.cartService.getPinCodeData(value).subscribe((res:any)=>{
        console.log(res);
        this.postOfficeList=res[0].PostOffice;
        console.log(this.postOfficeList);
        this.selectedPost=this.postOfficeList[0];

      })
    }

  }


  onPincodeChange(event){
    console.log(event);
    this.form.patchValue({ city: event.District });
    this.form.patchValue({ state_id: event.State });
  }

  submit(){

    this.form.markAllAsTouched();

    let action = new CreateAddress(this.form.value);

    // if(this.address) {
    //   action = new UpdateAddress(this.form.value, this.address.id);
    // }
    console.log(this.selectedAddress)
    console.log(this.form.value);
    let payload={
      ...this.form.value,isEdit:false
    }
    this.addAddress.emit(payload);
    this.modalService.dismissAll();

    // if(this.form.valid) {
    //   this.store.dispatch(action).subscribe({
    //     complete: () => {
    //       this.form.reset();
    //       if(!this.address){
    //         this.form?.controls?.['country_code'].setValue('91');
    //       }
    //     }
    //   });
    // }
  }

  update(){

    console.log(this.selectedAddress)
    console.log(this.form.value);
    let payload={
      ...this.form.value,isEdit:this.isEdit,addressId:this.selectedAddress.addressId
    }
    this.addAddress.emit(payload);
    this.modalService.dismissAll();

  }

  ngOnDestroy() {
    if(this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
