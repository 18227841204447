<ng-template #productDetailModal let-modal>
    <div class="modal-content">
        <div class="modal-header p-0">
            <app-button [type]="'button'" [class]="'btn btn-close'" [id]="'product_detail_close_modal_btn'" [spinner]="false" (click)="modal.dismiss('Cross click')">
                <i class="ri-close-line"></i>
            </app-button>
        </div>
        @if(product){
        <div class="modal-body">
            <div class="row g-sm-4 g-2">
                <div class="col-lg-6">
                  <div class="view-image-slider">
                    <owl-carousel-o [options]="productMainThumbSlider" #owlCar>
                                        <ng-container *ngFor="let image of product?.variantData; index as i">
                                            <ng-template carouselSlide [id]="image.variantId.toString()">
                                                <div>
                                                    <div class="slider-image">
                                                        <img [src]="image.media.thumbnail" class="img-fluid" alt="">
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                        <ng-container *ngIf="!product?.variantData?.length">
                                            <ng-template carouselSlide [id]="'1'">
                                                <div>
                                                    <div class="slider-image">
                                                        <img [src]="'assets/images/product.png'" class="img-fluid" alt="">
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                  </div>
                  <div class="col-12" >
                    <div class="bottom-slider-image left-slider no-arrow slick-top">
                        <owl-carousel-o [options]="productThumbSlider">
                            <ng-container *ngFor="let image of product?.variantData;">
                                <ng-template carouselSlide [id]="image.variantId.toString()">
                                    <div>
                                        <div class="slider-image" [class.active]="image.variantId.toString() == activeSlide.toString()">
                                            <img [src]="image ? image.media?.url
                                                    : 'assets/images/product.png'" class="img-fluid" alt=""
                                                    (click)="owlCar.to(activeSlide = image.variantId.toString())">
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </owl-carousel-o>
                    </div>
                </div>
                </div>
                <div class="col-lg-6">
                    <div class="right-sidebar-modal">
                        <h4 class="title-name">{{ product?.productTitle }}</h4>
                        <h4 class="price">
                            {{ product.price}} ₹
                        </h4>
                        <div class="product-package col-lg-2">
                            <ng-container *ngFor="let attribute of product.variantType; let i = index;">
                                <div class="product-title" >
                                    @if(attribute.optionType=='color_data'){
                                    <h4>Color</h4>
                                    }
                                    @if(attribute.optionType=='size_data'){
                                    <h4>Size</h4>
                                    }
                                    
                                </div>
                                <ng-container >
                                  <select id="input-state-{{i}}" class="form-control form-select" #selectedValue (change)="setVariant($event.target.value,attribute.optionType,owlCar)">
                                      <ng-container *ngFor="let value of attribute.optionValues; let indexx = index;">
                                          <option  [value]="value">
                                              {{ value }}
                                          </option>
                                      </ng-container>
                                  </select>
                                </ng-container>
                            </ng-container>
                            <!-- <app-product-variant [product]="product" [attributes]="product.variantType || []"
                                (selectVariation)="selectVariation($event!)" [owlCar]="owlCar">
                            </app-product-variant> -->
                        </div>
                         <div class="note-box product-package">
                            <div class="cart_qty qty-box product-qty">
                                <div class="input-group">
                                    <app-button [class]="'btn qty-left-minus'" [id]="'add-to-cart'+ product.checkedVariants?.variantId" [spinner]="false"
                                    (click)="addToCart(product?.checkedVariants, -1)">
                                        <i class="ri-subtract-fill"></i>
                                    </app-button>
                                    <input class="form-control input-number qty-input" type="text" name="quantity" value="{{product.checkedVariants?.qty && product?.checkedVariants?.qty ? product.checkedVariants.qty : 1}}" 
                                        readonly>
                                    <app-button [class]="'btn qty-left-minus'" [id]="'qty-left-minus'+product.checkedVariants?.variantId" [spinner]="false"
                                    (click)="addToCart(product?.checkedVariants, 1)">
                                        <i class="ri-add-line"></i>
                                    </app-button>
                                </div>
                            </div>
                        </div>

                        <div class="dynamic-checkout">
                            <app-button
                                [class]="'btn btn-md bg-theme'"
                                [id]="'buynowbtn'+product.checkedVariants?.variantId"
                                (click)="buyNow(product.checkedVariants)">
                                {{ 'Buy Now' | translate }}
                            </app-button>
                            <app-button [id]="'addcartbtnVariation'+product.checkedVariants?.variantId" [class]="'btn btn-md border-theme-color scroll-button'"
                                (click)="AddToVariantCart(product.checkedVariants)">
                                <i class="ri-shopping-cart-line me-1"></i> {{ 'Add To Cart' | translate }}
                            </app-button>
                        </div>

                        <div class="pickup-box">
                            <div class="product-title">
                                <h4>{{ 'Product Information' | translate }}</h4>
                            </div>
                            <div class="product-info">
                                <ul class="product-info-list product-info-list-2">
                                    <li *ngIf="product?.checkedVariants?.variantId"><span><b>SKU</b></span>:  <span class="sku">{{ product.checkedVariants?.sku }}</span></li>
                                    <li *ngIf="product?.weight"><span class="weight"><b>Weight</b></span>: <span class="weight">200</span> {{ 'gms' | translate }}</li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    }
    </div>
</ng-template>
