import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

    /**
     * Constructor
     */
    constructor(
      private _httpClient: HttpClient,private cookieService:CookieService) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  public get<T>(url: string, options?: {
      headers?: HttpHeaders | {
          [header: string]: string | string[];
      };
      params?: HttpParams | {
          [param: string]: string | string[];
      };
  }
  ): Observable<T> {
      try {
          return this._httpClient.get<T>(url, this.createAuthHeader(options));
      }
      catch (e) {
          console.log(e);
      }

  }

  public post<T>(url: string, body: any | null, options?: {
      headers?: HttpHeaders | {
          [header: string]: string | string[];
      };
      params?: HttpParams | {
          [param: string]: string | string[];
      };
  }
  ): Observable<T> {
      return this._httpClient.post<T>(url, body, this.createAuthHeader(options));
  }

  public put<T>(url: string, body: any | null, options?: {
      headers?: HttpHeaders | {
          [header: string]: string | string[];
      };
      params?: HttpParams | {
          [param: string]: string | string[];
      };
  }
  ): Observable<T> {
      return this._httpClient.put<T>(url, body, this.createAuthHeader(options));
  }

  public patch<T>(url: string, body: any | null, options?: {
      headers?: HttpHeaders | {
          [header: string]: string | string[];
      };
      params?: HttpParams | {
          [param: string]: string | string[];
      };
  }
  ): Observable<T> {
      return this._httpClient.patch<T>(url, body, this.createAuthHeader(options));
  }

  public delete<T>(url: string, options?: {
      headers?: HttpHeaders | {
          [header: string]: string | string[];
      };
      params?: HttpParams | {
          [param: string]: string | string[];
      };
      body?: any | null;
  }
  ): Observable<T> {
      return this._httpClient.delete<T>(url, this.createAuthHeader(options));
  }

  createAuthHeader(options): {
      headers?: HttpHeaders | {
          [header: string]: string | string[];
      };
      params?: HttpParams | {
          [param: string]: string | string[];
      };
      body?: any | null;
  } {

      const headerDict = {
          apiKey: env.apiKey,
          accept: 'application/json',
          "cookie-sc" : this.cookieService.get('_STR_C'),
      }

      if(options) {
          if(options.headers) {
              options.headers.push("apiKey", headerDict.apiKey);
              options.headers.push("accept", headerDict.accept);
          } else {
              options.headers = headerDict;
          }
      } else {
          options = {};
          options.headers = headerDict;
      }

      return options;
  }
}
