import { Component, ViewChild, TemplateRef, Input, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Product, Variation } from '../../../../interface/product.interface';
import { Cart, CartAddOrUpdate } from '../../../../interface/cart.interface';
import { AddToCart } from '../../../../action/cart.action';
import { CartState } from '../../../../state/cart.state';
import * as data from  '../../../../../shared/data/owl-carousel';
import { CartService } from 'src/app/shared/services/cart.service';
import { NavigationService } from 'src/app/shared/services/navgation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-detail-modal',
  templateUrl: './product-detail-modal.component.html',
  styleUrls: ['./product-detail-modal.component.scss']
})
export class ProductDetailModalComponent {

  @ViewChild("productDetailModal", { static: false }) productDetailModal: TemplateRef<any>;

  @Input() product: any;
  @Input() owlCar: any;
  selectedSize='';
  selectedColor='';

  @Select(CartState.cartItems) cartItem$: Observable<Cart[]>;

  public closeResult: string;
  public modalOpen: boolean = false;

  public cartItem: Cart | null;
  public productQty: number = 1;
  public selectedVariation:any | null;

  public activeSlide: string = '0';
  
  public productMainThumbSlider = data.productMainThumbSlider;
  public productThumbSlider = data.productThumbSlider;

  constructor(private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private cartService:CartService,
    private navService:NavigationService,
    private tostService:ToastrService
  ) {
  }

  ngOnInit() {
    this.cartItem$.subscribe(items => {
      this.cartItem = items.find(item => item.product.id == this.product.id)!;
    });
    console.log(this.product);
    for(let attribute of this.product.variantType){
      if(attribute.optionType=='color_data'){
          this.selectedColor=attribute.optionValues[0];
      }
      if(attribute.optionType=='size_data'){
          this.selectedSize=attribute.optionValues[0];
      }


  }
  }

  async openModal() {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalOpen = true;
      this.modalService.open(this.productDetailModal, {
        ariaLabelledBy: 'Product-Detail-Modal',
        centered: true,
        windowClass: 'theme-modal view-modal modal-lg'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  // selectVariation(variation: Variation) {
  //   this. = variation;
  // }

  updateQuantity(qty: number) {
    if(1 > this.productQty + (qty)) return;
    this.productQty = this.productQty + (qty);
    this.checkStockAvailable();
  }

  checkStockAvailable() {
    if(this.selectedVariation) {
      this.selectedVariation['stock_status'] = this.selectedVariation?.quantity < this.productQty ? 'out_of_stock' : 'in_stock';
    } else {
      this.product['stock_status']  = this.product?.quantity < this.productQty ? 'out_of_stock' : 'in_stock';
    }
  }

  // addToCart(product: Product) {
  //   if(product) {
  //     const params: CartAddOrUpdate = {
  //       id: this.cartItem && (this.selectedVariation && this.cartItem?.variation && 
  //         this.selectedVariation?.id == this.cartItem?.variation?.id) ? this.cartItem.id : null,
  //       product_id: product?.id!,
  //       product: product ? product : null,
  //       variation: this.selectedVariation ? this.selectedVariation : null,
  //       variation_id: this.selectedVariation?.id ? this.selectedVariation?.id! : null,
  //       quantity: this.productQty
  //     }
  //     this.store.dispatch(new AddToCart(params)).subscribe({
  //       complete: () => {
  //         this.modalService.dismissAll();
  //       }
  //     });
  //   }
  // }

  addToCart(variantData:any,qty:number){
    console.log(variantData);   
    if(variantData.qty){
        variantData.qty=variantData?.qty + qty;
    }else{
        variantData.qty= qty;
    }
    
  }

  buyNow(variant){
    if(variant.qty && variant.qty>0){
        this.cartService.addProductsToCart(variant).subscribe((res)=>{
        console.log(res);
        this.navService.goToCheckOut();
      })
    }else if(variant.qty==undefined){
      console.log(variant.qty)
      variant.qty=1;
      this.cartService.addProductsToCart(variant).subscribe((res)=>{
        console.log(res);
        this.navService.goToCheckOut();
      })
    }else{
      this.tostService.error('Quantity Not be negative','ERROR');
    }

}

AddToVariantCart(variant){
  console.log(variant);
  (window as any).fbq('track','AddToCart', {
      content_name: variant.productTitle, 
      content_ids: [variant.productId], 
    });    
  if(variant.qty && variant.qty>0){
      this.cartService.addProductsToCart(variant).subscribe((res)=>{
      console.log(res);
      })
  }else if(variant.qty==undefined){
    console.log(variant.qty)
    variant.qty=1;
     this.cartService.addProductsToCart(variant).subscribe((res)=>{
      console.log(res);
    })
  }else{
    this.tostService.error('Quantity Not be negative','ERROR');
  }

}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  selectVariation(event){
    console.log(event);
    this.selectedVariation=event;
    this.product = {...this.product,checkedVariants: this.selectedVariation};

}

setVariant(selectedValue, type,owlCarData){
  this.owlCar=owlCarData;
  console.log(selectedValue,type);
  if(type=='color_data'){
      this.selectedColor=selectedValue;
  }
  else if(type == 'size_data'){
      this.selectedSize=selectedValue;
  }

  console.log(this.selectedColor,this.selectedColor)

  let variantData=this.product.variantData.filter((variant)=> {
      return variant.size_data == this.selectedSize && variant.color_data == this.selectedColor
    });
    console.log(variantData);
    variantData[0]={...variantData[0],product: {
      productId: this.product?.productId,
      productTitle: this.product?.productTitle
    }};
    console.log(variantData);
    this.product = {...this.product,checkedVariants: variantData[0]};
    if(this.owlCar && variantData[0].variantId) {
      this.owlCar.to(variantData[0].variantId.toString());
    }

    this.selectVariation(variantData[0]);

}
  
}
