<div class="container">
    <div [ngClass]="rowClass()" class="row-class">
      <div class="col-md-12">
        <div class="hero-image">
          <img [src]="colData()[0].content.image" alt="Hero Image" class="img-fluid">
          <div class="hero-text col-md-5">
            <span>{{ colData()[0].content.title }}</span>
            <p>{{ colData()[0].content.desc }}</p>
            <button class="btn btn-primary" [routerLink]="colData()[0].content.buttonlink">Shop Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>