import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { DynamicData } from "../interface/dynamic-data.model";

@Injectable({
  providedIn: "root",
})
export class DynamicService {

    componentsRows = signal<DynamicData[]>([]);

    constructor(
      private httpService: HttpClient,
      private toastrService: ToastrService,
    ){

    }
}
