<!-- <div [ngClass]="rowClass()" class="row-class">
    @for (product of data.cols; track $index) {
    <div [id]="product?.id" class="column-class col-md-3 col-sm-12">
      <br>
      <app-product-box [productGadget]="product" [class]="'product-box-3'" [style]="'horizontal'"></app-product-box>    
    </div>
    }
  </div> -->

  <div [ngClass]="rowClass()" class="row-class">
    @for (item of data.cols; track $index) {
    <div [id]="item.id" [ngClass]="item.colClass" class="column-class" >
        <div class="card">
            <img [src]="item.content.image"  alt="Card image cap" class="card-img-top img-fluid w-50 mx-auto my-0" />
            <div class="card-body">
                <h4 class="card-title mt-0">{{ item.content.title }}</h4>
                <p class="card-text">
                    {{ item.content.desc }}
                </p>
                <a href="javascript: void(0);" class="btn btn-primary">Button</a>
            </div>
        </div>
    </div>
    }
</div>