<ng-template #profileModal let-modal>
    <div class="modal-header">
        <h3 class="fw-semibold">{{ 'edit_profile' | translate }}</h3>
        <app-button
            [class]="'btn btn-close'"
            [spinner]="false"
            [id]="'profile_modal_close_btn'"
            (click)="modal.dismiss('Cross click')">
            <i class="ri-close-line"></i>
        </app-button>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="!flicker">
        <div class="modal-body address-form">
            <div class="row g-4">
                <div class="col-xxl-12">
                    <div class="">
                        <label class="form-label" for="name">{{ 'full_name' | translate }}</label>
                        <input class="form-control" id="name" type="text" placeholder="{{ 'enter_name' | translate }}" formControlName="name" />
                        <div class="invalid-feedback" *ngIf="form?.controls?.['name']?.touched && form.controls['name']?.errors?.['required']">
                            {{ 'name_is_required' | translate }}
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="">
                        <label class="form-label" for="email">{{ 'email_address' | translate }}</label>
                        <input class="form-control" id="email" type="text" placeholder="{{ 'enter_email_address' | translate }}" formControlName="email" readonly>
                        <div class="invalid-feedback" *ngIf="form?.controls?.['email']?.touched && form.controls['email']?.errors?.['required']">
                            {{ 'email_address_is_required' | translate }}
                        </div>
                        <div class="invalid-feedback" *ngIf="form.controls['email'].touched && form.controls['email'].errors?.['email']">
                            {{ 'please_enter_valid_email' | translate }}
                        </div>
                    </div>
                </div>
                <div class="col-12 phone-field">
                    <div class="">
                        <label class="form-label" for="address1">{{ 'phone_number' | translate }}</label>
                        <select2
                            class="custom-select intl-tel-input"
                            [templates]="template"
                            [data]="codes"
                            formControlName="country_code">
                            <ng-template #template let-data="data">
                                <div class="country">
                                    <div class="flag-box">
                                        <div class="iti-flag {{data?.class}}"></div>
                                    </div>
                                    <span class="dial-code">{{data.code}}</span>
                                </div>
                            </ng-template>
                        </select2>
                        <input type="number" class="form-control intl-input-padding" formControlName="phone" placeholder="{{ 'enter_phone' | translate }}" name="phone">
                        <div class="invalid-feedback" *ngIf="form.controls['phone'].touched && form.controls['phone'].errors?.['required']">
                            {{ 'phone_number_is_required' | translate }}
                        </div>
                        <div class="invalid-feedback" *ngIf="form.controls['phone'].touched && form.controls['phone'].errors?.['pattern']">
                            {{ 'phone_number_is_invalid' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <app-button [class]="'btn btn-theme-outline btn-md fw-bold'" [type]="'button'" [spinner]="false"
                    [id]="'cancel_profile_btn'" (click)="modal.dismiss('Cancel')">
                    {{ 'cancel' | translate }}
                </app-button>
                <app-button [class]="'btn theme-bg-color btn-md fw-bold text-light'" [id]="'submit_profile_btn'">
                    {{ 'submit' | translate }}
                </app-button>
            </div>
        </div>
    </form>
</ng-template>
