<div class="container">
    <div [ngClass]="rowClass()" class="row-class">
      <div class="col-md-12">
        <div class="hero-image">
            <ngx-slick-carousel class="carousel" 
        #slickModal="slick-carousel" 
        [config]="slideConfig" 
        >
        @for (slide of colData()[0].content.images; track slide) {
            <div ngxSlickItem class="slide slick-slide">
                <img src="{{ slide }}" alt="" width="100%">
              </div>
        }
      </ngx-slick-carousel>
          <!-- <img [src]="colData()[0].content.image" alt="Hero Image" class="img-fluid"> -->
          <div class="hero-text col-md-5">
            <span>{{ colData()[0].content.title }}</span>
            <p>{{ colData()[0].content.desc }}</p>
            <button class="btn btn-primary">Shop Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>