import { Component, input, Input, OnInit } from "@angular/core";
import { DynamicCols } from "src/app/shared/interface/dynamic-data.model";
import { DynamicService } from "src/app/shared/services/dynamic.service";
import { ProductService } from "src/app/shared/services/product.service";
@Component({
  selector: "app-news-gadget",
  templateUrl: "./news-gadget.component.html",
  styleUrl: "./news-gadget.component.scss",
})
export class NewsGadgetComponent implements OnInit {
  colData = input<any[]>([], { alias: "data" });
  rowClass = input<any[]>([], { alias: "rowClass" });
  row=input<any>(null,{ alias:"row"});
  data:any;
  public skeletonItems = Array.from({ length: 12 }, (_, index) => index);

  constructor(private dynamicService: DynamicService,public productService: ProductService) {}

  ngOnInit(): void {
   
    console.log(this.row());
    this.data=this.row();
  }
}
