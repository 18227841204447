<div class="product-box" [class]="class">
    <!-- <div class="label-tag sale-tag" *ngIf="product.is_sale_enable else featured"><span>{{ 'sale' | translate  }}</span></div>
    <ng-template #featured>
        <div class="label-tag" *ngIf="product.is_featured"><span>{{ 'featured' | translate  }}</span></div>
    </ng-template> -->
    <div class="image">
        @if(type=='collection'){
            @if(selectedVariantData && selectedVariantData.media){
                <a>
                    <img [src]="selectedVariantData?.media?.thumbnail" [alt]="'product'">
                </a>

            }
        }@else{
            @if(productGadget?.content?.image){
                <img [src]="productGadget?.content?.image" class="img-fluid" [alt]="'product'">
            }@else if(productGadget?.content?.media[0]?.thumbnail){
                <img [src]="productGadget?.content?.media[0]?.thumbnail" class="img-fluid" [alt]="'product'">
            }
        }
        <!-- <a [routerLink]="['/product/', product.slug]">
            <img [src]="productGadget?.content?.image ?
            productGadget?.content?.image :
                        'assets/images/product.png'" class="img-fluid" [alt]="'product'">
        </a> -->
        <div class="product-image">
        <div class="product-header-top" *ngIf="close">
          <app-button
            [class]="'btn wishlist-button close_button'"
            [spinner]="false"
            [id]="'close_btn'"
            (click)="removeWishlist(productGadget?.id)">
              <i class="ri-close-line"></i>
          </app-button>
        </div>
        <ul class="product-option" *ngIf="!close">
            <li>
                <a href="javascript:void(0)" (click)="productDetailModal.openModal()">
                    <i class="ri-eye-line"></i>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" (click)="addToCompar(productGadget?.id)">
                    <i class="ri-refresh-line"></i>
                </a>
            </li>
            <li>
                <a href="javascript:void(0)" class="notifi-wishlist" (click)="addToWishlist(productGadget?.id)">
                    <i class="ri-heart-line"></i>
                </a>
            </li>
        </ul>
        </div>
    </div>
    <div class="product-detail">
        @if(type=='collection'){
            <a>
                <h6 class="name">{{ productGadget?.productTitle}}</h6>
            </a>
            <h5 class="sold text-content">
                <span class="theme-color price">{{ price }}</span>
                <!-- <del *ngIf="productGadget?.compareAtPrice">{{ productGadget?.compareAtPrice}}</del> -->
            </h5>

        }@else{
            <a>
                <h6 class="name">{{ productGadget?.content?.title }}</h6>
            </a>
            <h5 class="sold text-content">
                <span class="theme-color price">{{ price }}</span>
                <!-- <del *ngIf="productGadget?.content?.compareAtPrice">{{ productGadget?.content?.compareAtPrice}}</del> -->
            </h5>
            <div class="product-rating">
                <h6 class="theme-color" *ngIf="productGadget?.content?.stock_status == 'in_stock'">
                    {{ productGadget?.content?.stock_status | titleCase }}
                </h6>
            </div>

        }
        @if(productGadget?.content?.variantData && productGadget?.content?.variantData.length>0){
            <ng-container>
                <select class="size form-select" #selectedValue (click)="selectTheColor($event.target.value)">
                    <ng-container *ngFor="let value of colorValue; let indexx = index;">
                        <option  [value]="value">
                            <!-- {{ value.size_data}}/{{value.color_data}} -->
                              {{value}}
                        </option>
                    </ng-container>
                </select>
              </ng-container>
              <br>
              <ng-container>
                <select class="size form-select" #selectedValue (click)="selectedTheSize($event.target.value)">
                    <ng-container *ngFor="let value of sizeValue; let indexx = index;">
                        <option  [value]="value">
                            <!-- {{ value.size_data}}/{{value.color_data}} -->
                              {{value}}
                        </option>
                    </ng-container>
                </select>
              </ng-container>
        }@else {
            <ng-container>
                <select class="size form-select" #selectedValue (click)="selectTheColor($event.target.value)">
                    <ng-container *ngFor="let value of colorValue; let indexx = index;">
                        <option  [value]="value">
                            <!-- {{ value.size_data}}/{{value.color_data}} -->
                              {{value}}
                        </option>
                    </ng-container>
                </select>
              </ng-container>
              <br>

              <ng-container>
                <select class="size form-select" #selectedValue (click)="selectedTheSize($event.target.value)">
                    <ng-container *ngFor="let value of sizeValue; let indexx = index;">
                        <option  [value]="value">
                            <!-- {{ value.size_data}}/{{value.color_data}} -->
                              {{value}}
                        </option>
                    </ng-container>
                </select>
              </ng-container>
        }
          @if(type=='collection'){
            <div class="add-to-cart-box">
                <div [class.open]="productGadget?.checkedVariants?.qty && productGadget?.checkedVariants?.qty==0">
                    <app-button [id]="'add-to-cart'+productGadget?.checkedVariants?.variantId"
                    [class]="'btn btn-add-cart addcart-button'"
                    [iconClass]="'ri-add-line'"
                    [spinner]="false"
                    (click)="addToCart(productGadget?.checkedVariants, 1)"
                    >
                    {{ 'add' | translate  }}
                    <span class="add-icon">
                        <i class="ri-add-line"></i>
                    </span>
                </app-button>
                </div>
                <ng-template #outStock>
                    <app-button [id]="'out-of-stock'+productGadget?.checkedVariants?.variantId"
                        [class]="'btn btn-add-cart addcart-button'"
                        [spinner]="false"
                        [disabled]="true">
                        {{ 'sold_out' | translate  }}
                    </app-button>
                </ng-template>
                <div class="cart_qty qty-box" [class.open]="productGadget?.checkedVariants?.qty && productGadget?.checkedVariants?.qty > 0">
                    <div class="input-group">
                        <app-button
                            [id]="'qty-left-minus'+productGadget?.checkedVariants?.variantId"
                            [spinner]="false"
                            [class]="'qty-left-minus'"
                            (click)="addToCart(productGadget?.checkedVariants, -1)">
                            <i class="ri-subtract-line"></i>
                        </app-button>
                        <input class="form-control input-number qty-input" type="text" name="quantity"
                        value="{{productGadget?.checkedVariants?.qty && productGadget?.checkedVariants?.qty ? productGadget.checkedVariants.qty : 0}}"
                        readonly>
                        <app-button
                            [id]="'qty-right-plus'+productGadget?.checkedVariants?.variantId"
                            [spinner]="false"
                            [class]="'qty-left-plus'"
                            (click)="addToCart(productGadget?.checkedVariants, 1)">
                            <i class="ri-add-fill"></i>
                        </app-button>
                    </div>
                </div>
            </div>
          }@else{
            <div class="add-to-cart-box">
                <div [class.open]="productGadget?.content?.checkedVariants?.qty && productGadget?.content?.checkedVariants?.qty==0">
                    <app-button [id]="'add-to-cart'+productGadget?.content?.checkedVariants?.variantId"
                    [class]="'btn btn-add-cart addcart-button'"
                    [iconClass]="'ri-add-line'"
                    [spinner]="false"
                    (click)="addToCart(productGadget?.content?.checkedVariants, 1)"
                    >
                    {{ 'add' | translate  }}
                    <span class="add-icon">
                        <i class="ri-add-line"></i>
                    </span>
                </app-button>
                </div>
                <ng-template #outStock>
                    <app-button [id]="'out-of-stock'+productGadget?.content?.checkedVariants?.variantId"
                        [class]="'btn btn-add-cart addcart-button'"
                        [spinner]="false"
                        [disabled]="true">
                        {{ 'sold_out' | translate  }}
                    </app-button>
                </ng-template>
                <div class="cart_qty qty-box" [class.open]="productGadget?.content?.checkedVariants?.qty && productGadget?.content?.checkedVariants?.qty > 0">
                    <div class="input-group">
                        <app-button
                            [id]="'qty-left-minus'+productGadget?.content?.checkedVariants?.variantId"
                            [spinner]="false"
                            [class]="'qty-left-minus'"
                            (click)="addToCart(productGadget?.content?.checkedVariants, -1)">
                            <i class="ri-subtract-line"></i>
                        </app-button>
                        <input class="form-control input-number qty-input" type="text" name="quantity"
                            value="{{productGadget?.content?.checkedVariants?.qty && productGadget?.content?.checkedVariants?.qty ? productGadget.content.checkedVariants.qty : 0}}"
                            readonly>
                        <app-button
                            [id]="'qty-right-plus'+productGadget?.content?.checkedVariants?.variantId"
                            [spinner]="false"
                            [class]="'qty-left-plus'"
                            (click)="addToCart(productGadget?.content?.checkedVariants, 1)">
                            <i class="ri-add-fill"></i>
                        </app-button>
                    </div>
                </div>
            </div>
          }
        
    </div>
</div>
<app-product-detail-modal #productDetailModal [product]="product"></app-product-detail-modal>
<app-variation-modal #variationModal></app-variation-modal>
