<div class="container">
    <div class="row gy-xl-0 gy-3">
        <div class="col-lg-4">
            <div class="hero-image">
                <img [src]="colData()[0].content.image" alt="Hero Image" class="img-fluid">
                <div class="hero-text">
                  <span>{{ colData()[0].content.title }}</span>
                  <br>
                  <button class="btn btn-primary" [routerLink]="colData()[0].content.buttonlink">Shop Now</button>
                </div>
              </div>
            

        </div>

        <div class="col-lg-4">
            <div class="hero-image">
                <img [src]="colData()[1].content.image" alt="Hero Image" class="img-fluid">
                <div class="hero-text">
                  <span>{{ colData()[1].content.title }}</span>
                  <br>
                  <button class="btn btn-primary">Shop Now</button>
                </div>
              </div>

        </div>
        <div class="col-lg-4">
            <div class="hero-image">
                <img [src]="colData()[2].content.image" alt="Hero Image" class="img-fluid">
                <div class="hero-text">
                  <span>{{ colData()[2].content.title }}</span>
                  <br>
                  <button class="btn btn-primary">Shop Now</button>
                </div>
              </div>

        </div>

    </div>