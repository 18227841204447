import { Component, input, OnInit } from "@angular/core";
import { DynamicService } from "src/app/shared/services/dynamic.service";

@Component({
    selector: "app-hero-slide-gadget",
    templateUrl: "./hero-slide-gadget.component.html",
    styleUrl: "./hero-slide-gadget.component.scss",
  })
  export class HeroSlideGadgetComponent implements OnInit {
    colData = input<any[]>([], { alias: "data" });
    rowClass = input<any[]>([], { alias: "rowClass" });
    collection=input<any>(null,{ alias:"collection"});
    row=input<any>(null,{ alias:"row"});

    slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        dots: true
      };
  
    constructor(private dynamicService: DynamicService) {}
  
  
    ngOnInit(): void {
        console.log(this.colData()[0].content.slideConfig);
    }

}