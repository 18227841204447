<footer class="section-t-space" [ngClass]="footer.footer_class">
    <div class="container-fluid-lg">
        <div class="main-footer section-b-space">
            <div class="row g-md-4 g-3">
                <div class="col-xl-3 col-lg-4 col-sm-6">
                    <div class="footer-logo">
                        <app-footer-logo [data]="data!" [footer]="footer"></app-footer-logo>
                        <app-footer-about [data]="data"></app-footer-about>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6" *ngIf="data?.footer?.footer_categories?.length" (click)="toggle('categories')">
                    <div class="footer-title" [class.show]="active['categories']">
                        <h4>{{ 'categories' | translate }}</h4>
                    </div>
                    <div class="footer-contain">
                        <app-footer-categories [data]="data"></app-footer-categories>
                    </div>
                </div>
                <div class="col-xl col-lg-2 col-sm-3" *ngIf="data?.footer?.useful_link?.length" (click)="toggle('useful_link')">
                    <div class="footer-title" [class.show]="active['useful_link']">
                        <h4>{{ 'useful links' | translate }}</h4>
                    </div>
                    <div class="footer-contain">
                        <app-footer-links [links]="data?.footer?.useful_link || []"></app-footer-links>
                    </div>
                </div>
                <div class="col-xl-2 col-sm-3" *ngIf="data?.footer?.help_center?.length" (click)="toggle('help_center')">
                    <div class="footer-title" [class.show]="active['help_center']">
                        <h4>{{ 'Help Center' | translate }}</h4>
                    </div>
                    <div class="footer-contain">
                        <app-footer-links [links]="data?.footer?.help_center || []"></app-footer-links>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-sm-6"
                    *ngIf="data?.footer?.support_email ||
                        data?.footer?.support_number ||
                        data?.footer?.play_store_url ||
                        data?.footer?.app_store_url">
                    <div class="footer-title contact-title">
                        <h4>{{ 'contact us' | translate }}</h4>
                    </div>
                    <app-footer-contact [data]="data"></app-footer-contact>
                </div>
            </div>
        </div>
        <div class="sub-footer section-small-space">
            <div class="reserve" *ngIf="data?.footer?.footer_copyright">
                <app-footer-copyright [data]="data"></app-footer-copyright>
            </div>
            <div class="payment">
                <app-footer-payment-options></app-footer-payment-options>
            </div>
            <app-footer-social-links [data]="data" *ngIf="data?.footer?.social_media_enable"></app-footer-social-links>
        </div>
    </div>
</footer>
