import { Component, Input, ViewChild } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ProductDetailModalComponent } from '../../../widgets/modal/product-detail-modal/product-detail-modal.component';
import { Product } from '../../../../../shared/interface/product.interface';
import { CartAddOrUpdate, Cart } from '../../../../../shared/interface/cart.interface';
import { AddToWishlist, DeleteWishlist } from '../../../../../shared/action/wishlist.action';
import { AddToCompare } from '../../../../../shared/action/compare.action';
import { AddToCart, GetCartItems } from '../../../../../shared/action/cart.action';
import { CartState } from '../../../../../shared/state/cart.state';
import { VariationModalComponent } from '../../modal/variation-modal/variation-modal.component';
import { DynamicCols, DynamicData } from 'src/app/shared/interface/dynamic-data.model';
import { CartService } from 'src/app/shared/services/cart.service';
import { ToastrService } from 'ngx-toastr';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-product-box-horizontal',
  templateUrl: './product-box-horizontal.component.html',
  styleUrls: ['./product-box-horizontal.component.scss']
})
export class ProductBoxHorizontalComponent {

  @Input() product: Product;
  @Input() class: string;
  @Input() close: boolean;
  @Input() type:string;

  @Input() productGadget:any;

  @Select(CartState.cartItems) cartItem$: Observable<Cart[]>;

  @ViewChild("productDetailModal") productDetailModal: ProductDetailModalComponent;
  @ViewChild("variationModal") VariationModal: VariationModalComponent;

  public cartItem: Cart | null;
  public currentDate: number | null;
  public saleStartDate: number | null;
  price=0;
  sizeType= [
    {
      id: '1',
      name: 'S'
    },
    {
      id: '2',
      name: 'M'
    },
    {
      id: '3',
      name: 'L'
    },
    {
      id: '4',
      name: 'XL'
    }
  ];
  defaultLocation: any;
  selectedProductFulfillment=[];
  sizeValue=[];
  colorValue=[];
  selectedColor='';
  selectedSize='';
  selectedVariantData:any=[];

  constructor(private store: Store,
    config: NgbRatingConfig,private cartService:CartService,private toastrService:ToastrService) {
		config.max = 5;
		config.readonly = true;
	}

  ngOnInit() {
    // this.cartItem$.subscribe(items => {
    //   this.cartItem = items.find(item => item.product.id == this.product.id)!;
    // });

    console.log(this.productGadget,this.productGadget?.content?.image);
    console.log(this.productGadget);
    console.log(this.type);
    if(this.type=='collection'){
      if(this.productGadget.variantType[0].optionType=='size_data'){
        this.sizeValue=this.productGadget.variantType[0].optionValues;
        this.selectedSize=this.sizeValue[0];
      }else{
        this.colorValue=this.productGadget.variantType[0].optionValues;
        this.selectedColor = this.colorValue[0];
      }
      if(this.productGadget.variantType[1].optionType=='color_data'){
        this.colorValue=this.productGadget.variantType[1].optionValues;
        this.selectedColor = this.colorValue[0];
      }else{
        this.sizeValue=this.productGadget.variantType[1].optionValues;
        this.selectedSize=this.sizeValue[0];
      }
      this.selectTheVariant();
    }else{
      if(this.productGadget.content.variantType[0].optionType=='size_data'){
        this.sizeValue=this.productGadget.content.variantType[0].optionValues;
        this.selectedSize=this.sizeValue[0];
      }else{
        this.colorValue=this.productGadget.content.variantType[0].optionValues;
        this.selectedColor = this.colorValue[0];

      }
      if(this.productGadget.content.variantType[1].optionType=='color_data'){
        this.colorValue=this.productGadget.content.variantType[1].optionValues;
        this.selectedColor = this.colorValue[0];
      }else{
        this.sizeValue=this.productGadget.content.variantType[1].optionValues;
        this.selectedSize=this.sizeValue[0];

      }
      this.selectTheVariant();
    }

  }

  groupBy(arr, key: string): any {
    return arr.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  addToCart(product:any, qty: number) {
    console.log(product);
    (window as any).fbq('track','AddToCart', {
      content_name: product.productTitle, 
      content_ids: [product.productId], 
    });       
    if(product.qty){
      product.qty=product?.qty + qty;
      //this.selectedProductFulfillment[0].products.quantity=
    }else{
      product.qty= qty;
    }
    let count=0;
    console.log(product);
    this.cartService.addProductsToCart(product).subscribe((res)=>{
      console.log(res);
    })
    this.cartItem$.subscribe((res)=>{count = res.length});
    console.log(this.cartItem ? this.cartItem.id : null)
    const params: CartAddOrUpdate = {
      id: this.cartItem ? this.cartItem.id : null,
      product: product,
      product_id:count,
      variation_id: this.cartItem ? this.cartItem?.variation_id : null,
      variation: this.cartItem ? this.cartItem?.variation : null,
      quantity: qty
    }
    this.store.dispatch(new AddToCart(params));
    this.cartItem$.subscribe((res)=>{console.log(res)});
  }

  addToWishlist(id: number){
    this.store.dispatch(new AddToWishlist({ product_id: id }));
  }

  removeWishlist(id: number){
    this.store.dispatch(new DeleteWishlist(id));
  }

  addToCompar(id: number){
    this.store.dispatch(new AddToCompare({ product_id: id }));
  }

  selectTheColor(event){
    console.log(event);
    this.selectedColor=event;
    this.selectTheVariant();
  }

  selectedTheSize(event){
    console.log(event);
    this.selectedSize=event;
    this.selectTheVariant();
  }

  selectTheVariant(){
    console.log(typeof(this.selectedSize),this.selectedColor);
   
    if(this.type=='collection'){
      let variantData=this.productGadget.variantData.filter((variant)=> {
        return variant.size_data == this.selectedSize && variant.color_data == this.selectedColor
      });
      console.log(variantData);
      variantData[0]={...variantData[0],product: {
        productId: this.productGadget?.productId,
        productTitle: this.productGadget?.productTitle
      }};
      console.log(variantData);
      this.selectedVariantData=variantData[0];
      this.price=variantData[0].price
      this.productGadget = {...this.productGadget,checkedVariants: variantData[0]};

    }else{
      let variantData=this.productGadget.content.variantData.filter((variant)=> variant.size_data == this.selectedSize && variant.color_data == this.selectedColor);

      console.log(variantData);
      variantData[0]={...variantData[0],product: {
        productId: this.productGadget?.content?.productId,
        productTitle: this.productGadget?.content?.productTitle
      }};
      console.log(variantData);
      this.selectedVariantData=variantData;
      this.price=variantData[0].price
      this.productGadget.content = {...this.productGadget.content,checkedVariants: variantData[0]};
    }

    console.log(this.productGadget.content);

  }

  checkLocationHasQuantity(variant: any) {
    const location = variant.locations.find((loc) => {
      if (
        loc.quantity > 0 &&
        this.defaultLocation?.locationId === loc.locationId
      ) {
        return true;
      } else if (loc.quantity > 0) {
        return true;
      } else {
        return false;
      }
    });
    if (location === undefined) {
      this.toastrService.error(
        `${variant.sku} with ${variant.color_data}/${variant.size_data} is out of stock`,
        "Error"
      );
      return null;
    }
    return location;
  }

  private getOrderProduct(product: any, variant: any ) {
    console.log(product)
    const orderProduct: any = {
      orderProductId: uuidv4().slice(0,8),
      quantity: 1,
      price: variant.price,
      totalPrice: variant.price,
      weight:product.weight,
      product: {
        productId: product.productId,
        productTitle: product.productTitle
      },
      variant: {
        variantId: variant.variantId,
        media: variant.media,
        sku: variant.sku,
        orderVairant: [
          {variantKey:"color",variantValue:variant.color_data},
          {variantKey:"size",variantValue:variant.size_data},
        ]
      }
    }

    return orderProduct;
  }

}
