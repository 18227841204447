import { Component, OnInit, input } from "@angular/core";
import { DynamicService } from "src/app/shared/services/dynamic.service";
import { NavigationService } from "src/app/shared/services/navgation.service";
import { ThemeOptionService } from "src/app/shared/services/theme-option.service";



@Component({
    selector: "app-collection-gadget",
    templateUrl: "./collection-gadget.component.html",
    styleUrl: "./collection-gadget.component.scss",
  })
  export class CollectionGadgetComponent implements OnInit {
    colData = input<any[]>([], { alias: "data" });
    rowClass = input<any[]>([], { alias: "rowClass" });
    collection=input<any>(null,{ alias:"collection"});
    product:any=[];
  
    constructor(private dynamicService: DynamicService, private themeOptionService:ThemeOptionService,private navService:NavigationService) {
        //this.getProduct();
    }
  
  
    ngOnInit(): void {
    }

    getProduct(){
        this.themeOptionService.getCollection().subscribe((res:any)=>{
            console.log(res);
            this.product=res.body.products;
            console.log(this.product);
          })

    }

    goToCollection(){
      console.log(this.collection())
      this.navService.goToCollection(this.collection().collectionName)
      
    }
  }