import { Component, Input, ViewChild } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Cart, CartAddOrUpdate } from '../../../../interface/cart.interface';
import { CartState } from '../../../../state/cart.state';
import { DeleteCart, GetCartItems, ToggleSidebarCart, UpdateCart } from '../../../../action/cart.action';
import { ThemeOptionState } from '../../../../state/theme-option.state';
import { Option } from '../../../../interface/theme-option.interface';
import { SettingState } from '../../../../state/setting.state';
import { Values } from '../../../../interface/setting.interface';
import { VariationModalComponent } from '../../../widgets/modal/variation-modal/variation-modal.component';
import { CartService } from '../../../../services/cart.service';
import { NavigationService } from 'src/app/shared/services/navgation.service';

@Component({
  selector: 'app-header-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent {

  @Select(CartState.cartItems) cartItem$: Observable<Cart[]>;
  @Select(CartState.cartTotal) cartTotal$: Observable<number>;
  @Select(CartState.sidebarCartOpen) sidebarCartOpen$: Observable<boolean>;
  @Select(ThemeOptionState.themeOptions) themeOption$: Observable<Option>;
  @Select(SettingState.setting) setting$: Observable<Values>;

  @ViewChild("variationModal") VariationModal: VariationModalComponent;

  @Input() style: string = 'basic';

  public cartStyle: string = 'cart_sidebar';
  public shippingFreeAmt: number = 0;
  public cartTotal: number = 0;
  public shippingCal: number = 0;
  public confettiItems = Array.from({ length: 150 }, (_, index) => index);
  public confetti: number = 0;
  public loader: boolean = false;
  cart:any;

  constructor(private store: Store, public cartService: CartService,private navService:NavigationService) {
    this.store.dispatch(new GetCartItems());
    this.themeOption$.subscribe(option => this.cartStyle = option?.general?.cart_style);
    this.cartService.getCartItemsForUser().subscribe((res)=>{
      console.log(res);
    })

    // Calculation
    this.cartTotal$.subscribe(total => {
      this.setting$.subscribe(setting => this.shippingFreeAmt = setting?.general?.min_order_free_shipping);
      this.cartTotal = total;
      this.shippingCal = (this.cartTotal * 100) / this.shippingFreeAmt;
      if(this.shippingCal > 100) {
        this.shippingCal = 100;
        if(this.confetti == 0) {
          this.confetti = 1;
          setTimeout(() => {
            this.confetti = 2;
          }, 4500);
        }
      } else {
        this.confetti = 0;
      }
    });
  }

  cartToggle(value: boolean) {
    this.store.dispatch(new ToggleSidebarCart(value));
  }

  updateQuantity(item: any, qty: number) {

    if(item.qty){
      item.qty=item?.qty + qty;
    }else{
      item.qty= qty;
    }

    console.log(item);
    this.cartService.addProductsToCart(item).subscribe((res)=>{
      console.log(res);
      this.cart=res.body.cart;
    })
    // const params: CartAddOrUpdate = {
    //   id: item?.id,
    //   product_id: item?.product?.id,
    //   product: item?.product ? item?.product : null,
    //   variation_id: item?.variation_id ? item?.variation_id : null,
    //   variation: item?.variation ? item?.variation : null,
    //   quantity: qty
    // }
    // this.store.dispatch(new UpdateCart(params));
  }

  delete(id: number) {
    this.store.dispatch(new DeleteCart(id));
  }

  goToCheckOut(){
    let content=[];
    console.log(this.cartService.cartItem.products);
    for(let product of this.cartService.cartItem.products){
      let temp ={
        id: product?.product?.productId, 
        name: product?.product?.productTitle, 
        price: product.price, 
      };

      content.push(temp);
    }
    (window as any).fbq('track', 'InitiateCheckout', {
      value:this.cartService?.cartItem?.cartTotal, 
      currency: 'INR', 
      num_items: content.length,
      contents:content
    });
    this.navService.goToCheckOut();
  }

}
