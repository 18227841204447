// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'fackbackend',
  baseURL: 'https://web.dev.storeily.com/',
  apiServer:"https://dev.storeily.com/",
  URL: 'https://web.dev.storeily.com/assets/data',
  apiKey: "74eb72c3-7cbe-478b-972c-17c3fadefd16",
  geoLocationAPI: "https://eu1.locationiq.com/v1/reverse?key=pk.3174cf23ea064d9f26426e52881fac18&format=json",
  cometChatAppID: "207035b4fc0d81dd",
  cometChatRegion: "eu",
  majorVersion: 2.4,
  minorVersion: 0,
  buildVersion: 53,
  passwordTemplate: "Your Password for Storeily Dev account is ",
  passwordTemplateSubject: "Password for Storeily Dev account ",
  environment: "DEV",
  recordingDuration: 30,
  mixpanelToken: "cf1b2187a56ec010c8510de1e02cdc44",
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
