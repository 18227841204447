import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { Menu } from '../../../interface/menu.interface';
import { ProductState } from '../../../../shared/state/product.state';
import { Product, ProductModel } from '../../../../shared/interface/product.interface';
import * as data from '../../../../shared/data/menu'
import { HttpClient } from '@angular/common/http';
import { environment as env, environment } from 'src/environments/environment';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Select(ProductState.dealProducts) product$: Observable<Product[]>;

  public menu$: Observable<Menu[]>;
  public products: Product[];

  constructor(
    private httpClient:HttpClient
  ){
    this.product$.subscribe(product => {
      console.log(product)
      this.products = product.slice(0, 2);
    })
  }

  ngOnInit(): void {
      const url  = 'api/admin/web-menu?action=getDefaultWebMenu';
      this.menu$ = this.httpClient.get<any>(environment.apiServer + url,
        {
          headers:{
            apiKey: env.apiKey,
            accept: 'application/json'
        }
        }
      ).pipe(
        map((response)=>{
          if(response.statusCode === 200){
            return response.body.webMenu.menuItems;
          }
          return [];
        })
      )
  }

  toggle(menu: Menu){
    if(!menu.active){
      this.menu$.forEach(item => {
        if(item.includes(menu)){
          const index = item.findIndex((x)=>x.id ===  menu.id);
          if(index !== -1){
            item[index].active = false;
          }
        }
      })
    }
    menu.active = !menu.active;
  }
}
